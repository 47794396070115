<template>
  <div class="navigation_div"  id="topNav" :style="topNavBg">
    <div class="nav_content  allContentWidth">
      <div class="left_div">
        <div class="nav_img" @click="routeFun('home')">
          <img :src="imgSrc[0]"/>
        </div>
        <ul class="nav_ul">
          <li @click="routeFun('about_us')">
            <span>About us</span>
          </li>
          <li  @click="routeFun('main_business')">
            <span>Products</span>
            <div class="submenu">
              <swiper
              class="swiper"
              :options="swiperOption">
                <swiper-slide v-for="(i, index) in imgAll" :key="index">
                    <div class="slide_item" @click="clickFun(i)">
                      <img :src="i.i_src" alt="">
                        <div class="slide_text">
                          <img :src="i.icon" alt="">
                          <span>{{i.name}}</span>
                        </div>
                    </div>
                </swiper-slide>
              </swiper>
              <div class="swiper-button-prev swiper-button-prev-nav" slot="button-prev"></div>
              <div class="swiper-button-next swiper-button-next-nav" slot="button-next"></div>
            </div>
          </li>
          <li @click="routeFun('industry_case')">
            <span >Industry Solutions</span>
          </li>
          <li @click="routeFun('news')">
            <span>News</span>
          </li>
          <li @click="routeFun('join_us')">
            <span>Join us</span>
          </li>
          <li @click="routeFun('contact_us')">
            <span>Contact us</span>
          </li>
        </ul>
      </div>
      <div class="right_div">
        <div class="right_login">
          <!-- <div class="right_img">
            <img :src="imgSrc[1]"/>
          </div> -->
          <span class="login_span"><b @click="loginFun('client')">Cargo Tracking</b>&nbsp;&nbsp;|&nbsp;&nbsp;<b @click="loginFun('staff')">Employee</b></span>
          <span class="language_span">
            <b  v-for="(i, index) in langArr" :key="index" :class="{languageActive:active == i.id}" @click="languageFun(i)">{{i.name}}</b>
          </span>
        </div>
        <div class="toggle-menu" @click="selection">
          <div style="float: right; padding: 10px;">
            <Icon type="md-menu" size="28"/>
          </div>
        </div>
        <transition name="fade">
          <div class="selection-div" v-show="downSelection">
            <div @click="routeFun('about_us')">
              <Row  type="flex" justify="center">
                <Col span="10" class="selection-div-col">About us</Col>
              </Row>
            </div>
            <div  @click="routeFun('main_business')">
              <Row  type="flex" justify="center">
                <Col span="10" class="selection-div-col">Products</Col>
              </Row>
            </div>
            <div @click="routeFun('industry_case')">
              <Row  type="flex" justify="center">
                <Col span="10" class="selection-div-col">Industry Solutions</Col>
              </Row>
            </div>
            <div @click="routeFun('news')">
              <Row  type="flex" justify="center">
                <Col span="10" class="selection-div-col">News</Col>
              </Row>
            </div>
            <div @click="routeFun('join_us')">
              <Row  type="flex" justify="center">
                <Col span="10" class="selection-div-col">Join us</Col>
              </Row>
            </div>
            <div @click="routeFun('contact_us')">
              <Row  type="flex" justify="center">
                <Col span="10" class="selection-div-col selection-col5">Contact us</Col>
              </Row>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/libs/utils';
import {mapMutations, mapGetters} from 'vuex';
import store from '@/store';
export default {
  components: {},
  name: 'Navigation',
  computed: {},
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  watch: {

  },
  methods: {
    ...mapMutations(['setLanguage']),
    // 滚动页面时触发导航变色
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 设置背景颜色的透明度
      if (scrollTop) {
          this.topNavBg.backgroundColor = `rgba(44, 46, 50,
        ${scrollTop / (scrollTop + 60)})` // scrollTop + 多少根据自己的需求设置
      } else if (scrollTop === 0) {
        this.topNavBg.backgroundColor = 'transparent' // 设置回到顶部时，背景颜色为透明
      }
    },
    // 滚动之前重置
    beforeDestroy () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    selection(){
      this.downSelection = !this.downSelection
    },
    routeFun(arg){
      utils.scrollTo();
      if(this.downSelection === true){
        this.selection();
      }
      switch(arg){
        case 'home':
          this.go('/home_en');
          break;
        case 'about_us':
          this.go('/company_introduction_en');
          break;
        case 'main_business':
          this.go('/main_business_en');
          break;
        case 'industry_case':
          this.go('/industry_case_en');
          break;
        case 'news':
          this.go('/news_en');
          break;
        case 'join_us':
          this.go('/join_us_en');
          break;
        case 'contact_us':
          this.go('/contact_us_en');
          break;
        default:
          break;
      }
    },
    go(item){
      //在进行路由跳转时添加判断，若一致则不跳转
      if (this.$route.path !== item) {
        this.$router.push({ path: item })
      }
    },
    loginFun(text){
      switch(text){
        case 'client':
          window.open("https://sd4prd.webtracker.wisegrid.net/Login/Login.aspx?ReturnUrl=%2fDefault.aspx", "_blank");
          break;
        case 'staff':
          window.open("https://qy.163.com/login/?from=qiyebj", "_blank");
          break;
        default:
          break;
      }
    },
    languageFun(item){
      this.active = item.id
      this.setLanguage(item.value);
    },
    clickFun(i){
      let query = 'tabActive=' + i.id;
      this.$router.push(`/main_business_en`+ `?` + query );
    }
  },
  data () {
    var imgSrc = [];
    imgSrc.push(require('../assets/img/logo.png'));
    imgSrc.push(require('../assets/img/admin.png'));
    imgSrc.push(require('../assets/img/banner1.png'));
    return {
      downSelection:false,
      topNavBg: {
        backgroundColor: ''
      },
      imgSrc: imgSrc,
      imgAll:[
        {
          id: 1,
          name: 'Ocean Freight',
          icon: require('../assets/img/nav_hy_icon.png'),
          i_src: require('../assets/img/navigation_hy1.png')
        },
        {
          id: 2,
          name: 'Air Freight',
          icon: require('../assets/img/nav_ky_icon.png'),
          i_src: require('../assets/img/navigation_ky.png')
        },
        {
          id: 3,
          name: 'Contract Logistics',
          icon: require('../assets/img/nav_htwl_icon.png'),
          i_src: require('../assets/img/navigation_htwl1.png')
        },
        {
          id: 4,
          name: 'Inter-Continents Rail',
          icon: require('../assets/img/nav_zotl_icon.png'),
          i_src: require('../assets/img/navigation_zotl1.png')
        },
        {
          id: 5,
          name: 'Pharmaceutical Logistics',
          icon: require('../assets/img/nav_yywl_icon.png'),
          i_src: require('../assets/img/navigation_yywl1.png')
        },
        {
          id: 6,
          name: 'Project Cargo',
          icon: require('../assets/img/nav_xmwl_icon.png'),
          i_src: require('../assets/img/navigation_xmwl.png')
        },
      ],
      // Swiper 配置项
      swiperOption: {
        direction: 'horizontal', // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical 
        slidesPerView: 4, // Swiper的列数
        // spaceBetween: 30, //板块间距
        // 设置分页器
        // pagination: {
        //   el: ".swiper-pagination",
        //   type: 'bullets',//将分页的类型改为圆点（默认)
        //   clickable: true,// 点击可切换
        //   // hideOnClick :true, // 点击swiper-slide显隐
        // },
        loopedSlides: 6,
        //设置平滑
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next-nav",
          prevEl: ".swiper-button-prev-nav",
          disabledClass: 'my-button-disabled-nav', //前进后退按钮不可用时的类名
        },
        // 设置自动轮播
        // autoplay: {
        //   delay: 1000, // 2秒切换一次
        //   disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        // },
        loop: false, //无缝轮播
        grabCursor : true, // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
        observer: true,
        observeParents: true,
      },
      active:1,
      langArr:[
        {id: 1,name: 'EN',value: 'english'},
        {id: 2,name: '中',value: 'chinese'}
      ]
    }
  }
}
</script>

<style lang='less'>
.navigation_div{
  height:auto;
  display: flex;
  justify-content: center;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  // background: red;
  transition: color .4s,top .2s,background-color .4s;
  .nav_content{
    // width: 1200px;
    height:80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left_div{
      display: flex;
      align-items: center;
      flex: 1;
      flex-shrink: 0;
      height: 100%;
      .nav_img{
        width: 150px;
        height:68px;
        margin-right: 80px;
        cursor: pointer;
        img{
          height:100%;
          width:100%;
        }
      }
      .nav_ul{
        display: flex;
        height: 100%;
        li{
          display: flex;
          align-items: center;
          text-align: center;
          padding: 0 20px;
          cursor: pointer;
          // &:first-child{
          &:nth-child(2){
            .submenu{
              width: 100%;
              opacity: 0;
              visibility: hidden;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              padding: 120px 100px 47px;
              background: #1A1825;
              transition: all .4s;
              z-index: -2;
              .swiper-button-prev-nav {
                left: 30px;
                width: 18px;
                height: 40px;
                background-image: url('../assets/img/banner_left.png');
                background-size: 100% 100%;
                top: 50%;
                transform: translateY(-50%);
                &:after {
                  content: "";
                  width: 18px;
                  height: 40px;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  display: none;
                //   border-style: solid;
                }
              }
              .swiper-button-next-nav {
                right: 30px;
                width: 18px;
                height: 40px;
                background-image: url('../assets/img/banner_right.png');
                background-size: 100% 100%;
                top: 50%;
                transform: translateY(-50%);
                &:after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  display: none;
                  border-width: 10px;
                  // border-color: transparent transparent transparent #16f7ff;
                  border-style: solid;
                }
              }
              .my-button-disabled-nav{
                opacity:0.3 !important;
              }
              .swiper-slide{
                // width: 300px;
                .slide_item{
                  display: flex;
                  padding: 0 20px;
                  flex-direction: column;
                  .slide_text{
                    display: flex;
                    margin-top: 21px;
                    align-items: center;
                    justify-content: center;
                    img{
                      margin-right: 14px;
                    }
                  }
                }
              }
            }
            &:hover{
              .submenu{
                visibility: visible;
                opacity: 1;
                z-index: -1;
              }
            }
          }
        }
      }
    }
    .right_div{
      display: flex;
      align-items: center;
      height: 100%;
      .right_img{
        float: left;
        display: block;
        margin: 0 8px 0 0;
      }
      .login_span{
        cursor: pointer;
        b{
          font-weight: normal;
        }
      }
      .language_span{
        margin:0 0 0 15px;
        cursor: pointer;
        b{
          font-weight: normal;
          width: 28px;
          height: 18px;
          line-height: 18px;
          display: inline-block;
          text-align: center;
          border: 1px solid #fff;
        }
        .languageActive{
          background: #fff;
          color: #666
        }
      }
      .selection-div{
        width: 100%;
        height: auto;
        position: absolute;
        right: 0;
        top: 0px;
        border-radius: 0 0 5px 5px;
        background: #1A1825;
        padding-top: 100px;
        z-index: -1;
        .selection-div-col{
            color: #FFFFFF;
            font-size: 16px;
            line-height: 55px;
            text-align: center;
            border-bottom: 2px solid rgb(248, 241, 241);
        }
        .selection-col5{
            border-bottom: 0;
        }
      }
    }
  }
}
@media screen and (max-width:900px){
  .navigation_div{
    .nav_content{
      .left_div{
        .nav_img{
          width: 120px;
          height:60px;
        }
      }
    }
  }
}
</style>