<template>
  <div class="n-home">
    <Navigation v-if="languageMap==='chinese'"></Navigation>
    <NavigationEn v-if="languageMap!=='chinese'"></NavigationEn>
    <div class="content_all_big_box">
      <router-view></router-view>
    </div>
    <BottomFooter  v-if="languageMap==='chinese'"></BottomFooter>
    <BottomFooterEn v-if="languageMap!=='chinese'"></BottomFooterEn>
  </div>
</template>

<script>

import Navigation from '../components/Navigation.vue';
import BottomFooter from '../components/Bottom.vue';
import NavigationEn from '../components/NavigationEng.vue';
import BottomFooterEn from '../components/BottomEn.vue';
import store from '@/store';
import { mapGetters } from 'vuex';
import utils from '@/libs/utils';
export default {
  name:'index',
  components: { Navigation,BottomFooter,NavigationEn,BottomFooterEn },
  computed: {
      ...mapGetters(["language"]),
      languageMap() {
      // return this.$store.state.mapbox.map;
        return this.language===null?'chinese':this.language;
      }
  },
  mounted() {
    if(store.getters.language === null){
      this.skip(store.getters.language===null?'chinese':store.getters.language)
    }
  },
  watch: {
    languageMap(newData, oldData) {
      // console.log(newData)
      this.skip(newData)
    },
  },
  methods: {
    skip(newData){
      // console.log(newData)
      // if (this.$route.path !== '/home' || this.$route.path !== '/home_en') {
        utils.scrollTo();
        if(newData === 'english'){
            if(this.$route.name === "NewsChild"){ //若路由后面/:id的情况下需要单独处理
              var  str=this.$route.path;
              var lastStr=str.lastIndexOf('/');
              var newStr=str.substring(0,lastStr);
              this.$router.push({ path: `${newStr}_en/${this.$route.params.id}`})
            }else{
              this.$router.push({ path: `${this.$route.path}_en`})
            }
        }else{
          if(this.$route.name === "NewsChild" && this.$route.path.includes('_en')){
            var  str=this.$route.path;
            var lastStr=str.lastIndexOf('_');
            var newStr=str.substring(0,lastStr);
            this.$router.push({ path: `${newStr}/${this.$route.params.id}`})
          }else{
            if(this.$route.path.includes('_en')){ //判断是否从英文版跳转过来，若不做此判断刷新的时候会回到首页
              var  str=this.$route.path;
              var lastStr=str.lastIndexOf('_');
              var newStr=str.substring(0,lastStr);//截取最后一个_前面的
              this.$router.push({ path: newStr })
            }
          }
        }
      // }
    }
  },
  data () {
    return {
      
    }
  }
}
</script>

<style lang='less'>
.n-home{
  height: 100vh;
  display: flex;
  flex-direction: column;
  .content_all_big_box{
    flex: 1;
  }
}
</style>