<template>
    <div class="bottom_div_box">
        <div class="allContentWidth">
            <Row type="flex"  justify="space-around" class="footer">
                <Col class="bottom_logo_img"  >
                   <img :src="imgSrc[0]" @click="routeFun('home')"/>
                </Col>
                <Col class="info">
                    <h3>关于我们</h3>
                    <div>
                        <p  @click="routeFun('about_us')" class="hover_style">公司介绍</p>
                    </div>
                </Col>
                <Col class="info">
                    <h3>联系我们</h3>
                    <div>
                        <p @click="routeFun('contract_us')"  class="hover_style">联系方式</p>
                        <p @click="routeFun('join_us')" class="hover_style">加入我们</p>
                    </div>
                </Col>
                <Col class="info">
                    <h3>政策</h3>
                    <div>
                        <p @click="routeFun('yytk')" class="hover_style">运营条款</p>
                        <p @click="routeFun('flzm')" class="hover_style">法律声明</p>
                    </div>
                </Col>
                <Col class="info">
                    <h3>成员企业</h3>
                    <div>
                        <p @click="routeFun('rong_xin')" class="hover_style">融鑫供应链</p>
                        <p @click="routeFun('jnl')" class="hover_style">捷纳罗多式联运</p>
                    </div>
                </Col>
                <Col class="info">
                   <h3>社区</h3>
                    <Row type="flex">
                      <Col class="shequ_col">
                        <img :src="imgSrc[1]" @click="routeFun('Twiter')"/>
                      </Col>
                      <Col class="shequ_col">
                        <img :src="imgSrc[3]" @click="routeFun('facebook')"/>
                      </Col>
                      <Col class="shequ_col">
                        <img :src="imgSrc[4]"  @click="routeFun('instagram')"/>
                      </Col>
                      <Col class="shequ_col">
                        <img :src="imgSrc[5]"  @click="routeFun('youtube')"/>
                      </Col>
                       <Col class="shequ_col">
                        <img :src="imgSrc[2]"/>
                        <div class="QR_code_div">
                            <img :src="imgSrc[6]"/>
                        </div>
                      </Col>
                    </Row>
                </Col>
            </Row>
            <Row type="flex"  justify="center" class="bottom_text">
                <p>CopyRight © {{yearStr}} Eastrong International Logistics Co.,Ltd (沪ICP备2022021843号)</p>
            </Row>
        </div>
    </div>
</template>

<script>
import utils from '@/libs/utils';
export default {
    components: {},
    name:'Bottom',
    computed: {},
    mounted() {
        this.yearStr = utils.getYear();
    },
    watch: {},
    methods: {
        routeFun(arg){
            utils.scrollTo();
            switch(arg){
                case 'home':
                    this.go('/home');
                    break;
                case 'about_us':
                   this.go('/company_introduction');
                    break;
                case 'contract_us':
                    this.go('/contact_us');
                    break;
                case 'join_us':
                    this.go('/join_us');
                    break;
                case 'rong_xin':
                    window.open('http://www.integratedlm.com/home','_blank'); //正式环境
                    //  window.open('http://dz-rx.zw.adwetec.cn/home','_blank'); //测试环境
                    // window.open('http://172.16.1.211:8082/home','_blank'); //本地
                    break;
                case 'jnl':
                    window.open('http://www.shsgit.com/home','_blank'); //正式环境
                    // window.open('http://dz-shsgit.zw.adwetec.cn/home','_blank'); //测试环境
                    // window.open('http://172.16.1.211:8081/home','_blank'); //本地
                    break;
                case 'Twiter':
                    window.open('https://twitter.com/EastrongCo','_blank');
                    break;
                case 'facebook':
                    window.open('https://www.facebook.com/profile.php?id=100082989914855','_blank');
                    break;
                 case 'instagram':
                    window.open('https://www.instagram.com/eastrongintl','_blank');
                    break;
                case 'youtube':
                    window.open('https://youtu.be/rS9mNd6AxWc','_blank');
                    break;
                case 'yytk':
                    this.go('/operating_terms');
                    break;
                case 'flzm':
                    this.go('/legal_notices');
                    break;
                default:
                    break;
            }
        },
        go(item){
            //在进行路由跳转时添加判断，若一致则不跳转
            if (this.$route.path !== item) {
                this.$router.push({ path: item })
            }
        },
    },
    data () {
        var imgSrc = [];
        imgSrc.push(require('../assets/img/logo.png'));//(0)
        imgSrc.push(require('../assets/img/feiniao.png')); //(1)
        imgSrc.push(require('../assets/img/wechat.png')); //(2)
        imgSrc.push(require('../assets/img/f.png'));//(3)
        imgSrc.push(require('../assets/img/instagram.png')); //(4)
        imgSrc.push(require('../assets/img/video.png')); //(5)
        imgSrc.push(require('../assets/img/code.png')); //(6)
        return {
            imgSrc: imgSrc,
            yearStr: ''
        }
    }
}
</script>

<style lang='less'>
.bottom_div_box{
    height:auto;
    display: flex;
    justify-content: center;
    background: #2C2E32;
    color: #fff;
    padding: 50px 0 30px 0;
    line-height: 30px;
    .bottom_logo_img{
        width: 140px;
        height:80px;
        cursor: pointer;
        img{
          height:100%;
          width:100%;
        }
    }
    .info{
        line-height: 40px;
    }
    .shequ_col{
        width: 32px;
        height: 26px;
        display: block;
        margin: 15px 20px 0 0;
        cursor: pointer;
        img{
            height:100%;
            width:100%;
        }
        .QR_code_div{
            display: none;
        }
        &:last-child{
            &:hover{
                .QR_code_div{
                    display: block;
                    width: 120px;
                    margin: 25% 0 0 -130%;
                }
            }
        }
    }
    .bottom_text{
        margin: 40px 0 0 0;
    }
    .hover_style{
        cursor: pointer;
    }
}
@media screen and (max-width:900px){
    .allContentWidth{
        .footer{
            display: flex;
            flex-direction: column;
            padding: 0 15px;
            .bottom_logo_img{
                margin-bottom: 10px;
            }
            .info{
                div{
                    display: flex;
                    p{
                        margin-right: 20px;
                    }
                }
                .shequ_col{
                    &:last-child{
                       display: none;
                    }
                }
            }
        }
        .bottom_text{
            padding: 0 15px;
        }
    }
}
</style>